<template>
  <div class="system_warp">
    <el-button style="margin-bottom: 10px" @click="handleAdd"
      >添加角色</el-button
    >

    <el-table :data="tableData" stripe height="590" style="width: 100%">
      <el-table-column prop="id" label="ID"> </el-table-column>
      <el-table-column prop="typesname" label="角色类型"> </el-table-column>
      <!-- <el-table-column prop="phone" label="手机号"> </el-table-column> -->
      <!-- <el-table-column
        prop="procuratorate.procuratoratename"
        label="所属检察院"
      >
      </el-table-column>
      <el-table-column prop="types.typesname" label="角色类型">
      </el-table-column> -->
      <el-table-column label="操作" class="operate" min-width="100px">
        <template slot-scope="scope">
          <el-button size="mini" @click="handle(scope.row, scope.row.id)"
            >编辑</el-button
          >
          <el-button size="mini" @click="handleRemove(scope.row.id)"
            >删除</el-button
          >
        </template>
      </el-table-column>
    </el-table>
    <el-dialog
      title="添加角色类型"
      :visible.sync="dialogVisible"
      :close-on-click-modal="false"
    >
      <el-form :model="form">
        <el-form-item label="角色类型：" label-width="100px">
          <el-input
            v-model="form.typesname"
            placeholder="请输入信息"
            autocomplete="off"
          />
        </el-form-item>
        <el-form-item label="权限：" label-width="100px">
          <el-tree
            ref="tree"
            :data="PermissionList"
            show-checkbox
            default-expand-all
            :default-checked-keys="permissionslist"
            node-key="id"
            :props="defaultProps"
          >
          </el-tree>
        </el-form-item>
         <el-form-item label="备注：" label-width="100px">
          <el-input
            v-model="form.note"
            placeholder="请输入信息"
            autocomplete="off"
          />
        </el-form-item>
      </el-form>
      <template #footer>
        <span class="dialog-footer">
          <el-button @click="dialogVisible = false">取 消</el-button>
          <el-button type="primary" @click="handleClick">确 定</el-button>
        </span>
      </template>
    </el-dialog>
  </div>
</template>

<script>
// import md5 from "md5";
import { Message } from "element-ui";
import {
  getPermissionsList,
  getTypeList,
  updateTypes,
  deleteTypes,
  insertTypes,
} from "@/api/api";
export default {
  name: "xxx",
  components: {},
  data() {
    return {
      perList: [],
      currentValue: {},
      dialogFormVisible: false,
      dialogVisible: false,
      form: {},
      tableData: [],
      proList: [],
      showPassword: false,
      currentId: 0,
      PermissionList: [],
      permissionslist: [],
      defaultProps: {
        children: "permissionsList",
        label: "permissionsname",
      },
      disabled: false,

      id: "",
    };
  },
  mounted() {
    this._getTypeList();
    this._getPermissionsList();
    this.perList = JSON.parse(window.localStorage.getItem("permissionidList"));
    console.log(this.perList);
    this.id = JSON.parse(window.localStorage.getItem("tltz_userinfo")).id;
    console.log(this.id);
  },
  methods: {
    //获取权限列表
    _getPermissionsList() {
      getPermissionsList().then((data) => {
        this.PermissionList = data.data;
        // this.permissionslist = [];
      });
    },
    //获取角色类型
    _getTypeList() {
      getTypeList().then((data) => {
        this.tableData = data.data.list;
        this.total = data.data.total;
        this.currentPage = data.data.pageNum;
      });
    },
    handleAdd() {
      let res = this.perList.filter((val) => val == 83);
      console.log(res)
      if(!res.length){
        Message.error('您没有这个权限');

      }else{
      this.currentId = 0;
      this.form = {};
      console.log(this.permissionslist)
      if(this.permissionslist.length !== 0){
        this.$refs.tree.setCheckedKeys([])
      }
      
      this.showPassword = false;
      this.currentValue.account = false;
      this.dialogVisible = true;

      }
    },
    handle(value, id) {
       let res = this.perList.filter((val) => val == 84);
      console.log(res)
      if(!res.length){
        Message.error('您没有这个权限');

      }else{
      this.dialogVisible = true;
      console.log(value, id);
      this.currentId = id;
      this.form = value;
      console.log(value)
      const permissionslist =[]
      value.perlist.forEach(item => {
        permissionslist.push(parseInt(item))
      });
      this.permissionslist= permissionslist;
      console.log(this.form)
      // updateTypes(id).then((data) => {
      //   if(data.code == 500){
      //     this.checkedList = [];
      //     return;
      //   }
      //   console.log(data.data.permissionid)
      //   this.checkedList = data.data.permissionid;
      // })
      }
    },
    handleRemove(id) {
      let res = this.perList.filter((val) => val == 85);
      console.log(res)
      if(!res.length){
        Message.error('您没有这个权限');

      }else{
      this.$confirm("是否删除该用户?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          deleteTypes(id).then(async (data) => {
            if (data.code !== 200) {
              Message.error("操作失败！");
              return;
            }
            this.dialogFormVisible = false;
            Message.success("提交成功！");
            await this._getTypeList();
          });
        })
        .catch(() => {
          Message.info("取消删除！");
        });
      }
    },
    handleClick() {
      let id = this.currentId;
      let { typesname } = this.form;
        let { note } = this.form;
        let node = this.$refs.tree.getCheckedNodes()
        let pids = []
        if (node.length != 0) {
          for (let i = 0; i < node.length; i++) {
            pids.push(node[i].id)
          }
          // params.pids = JSON.stringify(pids)
        }
      if (!id) {
        const params = {
          typesname,
          note,
          permissionslist: JSON.stringify(pids)
        }
        if (!typesname || !note || pids == '') {
          Message.error("请检查信息填写是否完整！");
          return;
        }
        insertTypes(params).then(async (data) => {
          if (data.code !== 200) {
            Message.error("操作失败！");
            return;
          }
          this.dialogVisible = false;
          Message.success("提交成功！");
          await this._getTypeList();
        });
      } else {
        const params = {
          id,
          typesname,
          note,
          permissionslist: JSON.stringify(pids)
        }
        updateTypes(params).then(async (data) => {
          if (data.code !== 200) {
            Message.error("操作失败！");
            return;
          }
          this.dialogVisible = false;
          Message.success("提交成功！");
          await this._getTypeList();
        });
      }
    },
  },
};
</script>
<style lang='less' >
.system_warp {
  box-sizing: border-box;
  position: relative;
  width: auto;
  height: 92%;
  background: #fff;
  margin-top: 16px;
  padding: 20px 13px 0 13px;
  .cell {
    text-align: center;
  }
}
</style>